#typing-text-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1rem;
  #typing-text {
    padding-left: 6.5%;
    font-size: 3vw;
    min-height: 3vw;
  }

  .contact {
      margin-left: 7%;
      // background-color: red;
      display: flex;
    align-items: center;

    img:hover {
      cursor: pointer;
    }
}
}