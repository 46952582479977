code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "rand bold";
  src: url(./assets/local-fonts/Rand-Bold1.otf) ;
}

@font-face {
  font-family: "rand light";
  src: url(./assets/local-fonts/Rand-Light1.otf) ;
}


body {
  font-family: "rand bold" !important;
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

