#calligraphy-screen {
  background-color: black;
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

    .contact {
    height: min-content;
    display: flex;
    margin-left: 7%;
  }
  .text-content {
    margin-left: 7vw;
    font-family: "rand light";
    color: white;

    .text {
      margin: 0.5rem 0rem;
    }

    a:link {
      color: white;
      background-color: transparent;
      text-decoration: underline;
    }

    a:visited {
      color: white;
      background-color: transparent;
      text-decoration: underline;
    }

    a:hover {
      color: white;
      background-color: transparent;
      text-decoration: underline;
    }

    a:active {
      color: white;
      background-color: transparent;
      text-decoration: underline;
    }
  }
}